import { createAction, props } from '@ngrx/store';
import { User } from '../../shared/common/models/user';

export enum AuthActionTypes {
  LOGIN_REQUEST = '[Auth] login request',
  LOGIN_RESPONSE_SUCCESS = '[Auth] login success response',
  LOGIN_RESPONSE_FAILURE = '[Auth] login failure response',
  TOKEN_REQUEST = '[Auth] token request',
  TOKEN_REFRESH_RESPONSE_SUCCESS = '[Auth] token refresh success response',
  RESET_LOGIN_ERROR = '[Auth] reset login error',
  LOGOUT = '[Auth] logout',
  LOGOUT_SUCCESS = '[Auth] logout success',
  LOGIN_STATE_CREATE = '[Auth] state create'
}

export const logoutAction = createAction(
  AuthActionTypes.LOGOUT
);
export const loginRequest = createAction(
  AuthActionTypes.LOGIN_REQUEST,
  props<{ username: string, password: string }>()
);
export const loginSuccessResponse = createAction(
  AuthActionTypes.LOGIN_RESPONSE_SUCCESS,
  props<{ user: User }>()
);
export const loginFailureResponse = createAction(
  AuthActionTypes.LOGIN_RESPONSE_FAILURE,
  props<{ errors: string[] }>()
);
export const tokenRequest = createAction(
  AuthActionTypes.TOKEN_REQUEST,
  props<{ code: string }>()
);
export const tokenRefreshSuccessResponse = createAction(
  AuthActionTypes.TOKEN_REFRESH_RESPONSE_SUCCESS,
  props<{ user: User }>()
);
export const resetLoginErrorAction = createAction(
  AuthActionTypes.RESET_LOGIN_ERROR
);
export const loginStateCreateAction = createAction(
  AuthActionTypes.LOGIN_STATE_CREATE,
  props<{ loginState: string }>()
);
