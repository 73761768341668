import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app-state';
import { EnvironmentService } from 'app/shared/common/services/env.service';
import { UserRoleUtils } from 'app/shared/common/services/user.role.utils';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { User } from '../../shared/common/models/user';
import { isAuthenticatedSelector, loggedInUserSelector } from '../state/auth.reducers';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private logger: NGXLogger
  ) {
    // check periodically isAuthentaticated state
    setInterval(
      () => {
        this.store.select(isAuthenticatedSelector).subscribe((isAuthenticated: boolean) => {
          if (!isAuthenticated) {
            this.router.navigate(['/auth/login']);
          }
        }, () => {
          this.router.navigate(['/auth/login']);
        })
      },
      5000
    );
  }
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.logger.trace('canActivate', route, state);

    if (state.url.startsWith('/auth')) {
      return true;
    }

    // enable old report stuff for local and dev
    if (state.url.startsWith('/report')) {
      this.store.select(isAuthenticatedSelector).subscribe((isAuthenticated: boolean) => {
        const allwd: boolean = !!isAuthenticated
                      && (EnvironmentService.isLocal()
                      || EnvironmentService.isDev());
        if (!allwd) {
          this.router.navigate(['/auth/login']);
        }
        return of(allwd);
      }),
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return of(false);
      });
    }

    if (state.url.startsWith('/incident/create/new')) {
      this.store.select(loggedInUserSelector).subscribe((user: User) => {
        const isOperatorOrUser: boolean = UserRoleUtils.hasRoleOperatorOrUser(user);
        if (!isOperatorOrUser) {
          this.router.navigate(['/incident/list']);
        }
        return of(!!isOperatorOrUser);
      });
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return of(false);
      })
    };

    if (state.url.startsWith('/shared/settings')) {
      this.store.select(loggedInUserSelector).subscribe((user: User) => {
        const isOperator: boolean = UserRoleUtils.hasRoleOperator(user);
        if (!isOperator) {
          this.router.navigate(['/incident/list']);
        }
        return of(!!isOperator);
      });
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return of(false);
      })
    };

    return this.store.select(isAuthenticatedSelector).pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigate(['/auth/login']);
        }
        return of(isAuthenticated);
      }),
      catchError(() => {
        this.router.navigate(['/auth/login']);
        return of(false);
      })
    );
  }
}
