import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { User } from 'app/shared/common/models/user';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserInfoService } from '../../services/user.info.service';
import { UserRoleUtils } from '../../services/user.role.utils';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  @Input() user: User;
  @Output() logout: EventEmitter<void> = new EventEmitter();

  UserRoleUtils = UserRoleUtils;
  avatarUrl$: Observable<SafeUrl>;
  public readonly defaultAvatar = this.sanitizer.bypassSecurityTrustUrl(
    '/assets/icons/default-avatar.png'
  );

  constructor(
    private userInfoService: UserInfoService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.loadUserPicture();
  }

  loadUserPicture(): void {
    if (!this.user?.email) {
      this.avatarUrl$ = of(this.defaultAvatar);
      return;
    }

    this.avatarUrl$ = this.userInfoService.getUserPhoto(this.user.email).pipe(
      map((result) => {
        const objectURL = URL.createObjectURL(result);
        return this.sanitizer.bypassSecurityTrustUrl(objectURL);
      }),
      catchError((err) => {
        console.error("Error occured while loading the user's avatar: ", err);
        return of(this.defaultAvatar);
      })
    );
  }

  handleLogoutClick() {
    this.logout.emit();
  }
}
