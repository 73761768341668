import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppRoutingModule } from './app-routing.module';
import { metaReducers } from './app-state';
import { AppComponent } from './app.component';
import { AuthEffects } from './auth/state/auth.effects';
import { authReducer } from './auth/state/auth.reducers';
import { MatPaginatorI18n } from './shared/common/services/paginator.i18n';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { SharedModule } from './shared/shared.module';
import { SharedEffects } from './shared/state/shared.effects';
import { sharedReducer } from './shared/state/shared.reducers';
import { incidentReducer } from './incidents/state/incidents.reducers';
import { HttpConfigInterceptor } from './shared/common/interceptors/http-config-interceptor';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const defaultTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MatIconModule,
        MatTableModule,
        MatSortModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatSidenavModule,
        MatTooltipModule,
        MatMenuModule,
        MatSnackBarModule,
        SharedModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.LOG,
            serverLogLevel: NgxLoggerLevel.OFF,
        }),
        // ngrx
        StoreModule.forRoot({}, { metaReducers }),
        StoreModule.forFeature('auth', authReducer),
        StoreModule.forFeature('shared', sharedReducer),
        StoreModule.forFeature('incidents', incidentReducer),
        StoreDevtoolsModule.instrument({
            name: 'DiCon',
            maxAge: 25,
            logOnly: true,
        }),
        EffectsModule.forRoot([AuthEffects, SharedEffects]),
    ],
    bootstrap: [
        AppComponent,
    ],
    exports: [
        ReactiveFormsModule,
    ],
    providers: [
        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorI18n,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        {
            provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
            useValue: defaultTooltipOptions
        }
    ]
})
export class AppModule { }
